export default [
    {
        path: 'beheer/apparaten',
        name: 'devices',
        component: (): any => import(/* webpackChunkName: 'devices' */ './devices/views/DevicesView.vue'),
        meta: { name: 'Apparaten' },
    },
    {
        path: 'beheer/trailers',
        name: 'trailers',
        component: () => import(/* webpackChunkName: 'trucks' */ './trailers/views/TrailerIndex.vue'),
        meta: { name: 'Trailers' },
    },
];
