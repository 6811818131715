import { loginService } from '@/main';
import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import accountRoutes from '@/modules/account/routes';
import manageRoutes from '@/modules/management/routes';
import planboardRoutes from '@/modules/planboard/routes';
import jobRoutes from '@/modules/job/routes';
Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '/',
        component: () => import(/* webpackChunkName: 'menu' */ '../views/Menu.vue'),
        redirect: { name: 'planboard-week' },
        children: [
            {
                path: '',
                name: 'home',
                component: () => import(/* webpackChunkName: 'home' */ '../views/Home.vue'),
                redirect: { name: 'planboard-week' },
            },
            {
                path: 'relaties',
                name: 'relations',
                component: () =>
                    import(/* webpackChunkName: 'relations' */ '../modules/relation/views/RelationIndex.vue'),
                meta: { name: 'Relatiebeheer' },
            },
            {
                path: 'relaties/:id',
                name: 'relation',
                component: () =>
                    import(/* webpackChunkName: 'relations' */ '../modules/relation/views/RelationDetail.vue'),
                meta: { parent: 'relations' },
            },
            {
                path: 'personeel',
                name: 'staff',
                component: () => import(/* webpackChunkName: 'staff' */ '../modules/staff/views/StaffOverview.vue'),
                meta: { name: 'Relatiebeheer' },
            },
            {
                path: 'beheer/materiaal',
                name: 'equipments',
                component: () =>
                    import(/* webpackChunkName: 'materials' */ '../views/overall-management/EquipmentIndex.vue'),
                meta: { name: 'Materialen' },
            },
            {
                path: 'beheer/materaal-groepen',
                name: 'equipment-groups',
                component: () =>
                    import(/* webpackChunkName: 'materials' */ '../views/overall-management/EquipmentGroupIndex.vue'),
                meta: { name: 'Materiaal groepen' },
            },
            {
                path: 'beheer/ondersteuning',
                name: 'support-vehicles',
                component: () =>
                    import(/* webpackChunkName: 'support' */ '../views/overall-management/SupportVehicleIndex.vue'),
                meta: { name: 'Ondersteuning' },
            },
            ...manageRoutes,
        ],
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: 'about' */ '../views/Login.vue'),
    },
    {
        path: '/privacy-policy',
        name: 'privacy',
        component: () => import(/* webpackChunkName: 'privacy' */ '../views/privacy/Privacy.vue'),
    },
    ...accountRoutes,
    ...planboardRoutes,
    ...jobRoutes,
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    linkActiveClass: 'active',
});

router.beforeEach((to: Route, from: Route, next) => {
    const anonymousRoutes = ['login', 'accept-invite', 'privacy'];
    if (to.name && !loginService.isLoggedIn() && anonymousRoutes.indexOf(to.name) < 0) {
        next({ name: 'login' });
    }
    next();
});

export default router;
