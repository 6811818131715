import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class MeterInputComponent extends Vue {
    @Prop() public value: number;

    public get displayValue(): number {
        return this.value / 1000;
    }

    public onInput(numericValue: number): void {
        const value = numericValue * 1000;
        this.$emit('input', value);
    }
}
