import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ExpansionPanelComponent extends Vue {
    @Prop() public title: string;
    @Prop({ type: Boolean }) public visible: boolean;
    public collapse: boolean = false;

    public mounted(): void {
        this.collapse = this.visible;
    }

    public toggle(): void {
        this.collapse = !this.collapse;
    }
}
