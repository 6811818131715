import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import BaseCrudModal from '../BaseCrudModal';

@Component
export default class CrudModalComponent extends Vue {
    @Prop() public title: string;
    @Prop({ type: Boolean }) public loading: boolean;
    @Prop({ type: String, default: 'sm' }) public size: string;
    @Prop({ type: Boolean }) public enableSave: boolean;
    @Prop({ type: Boolean }) public enableDelete: boolean;
    @Prop({ type: Boolean }) public show: boolean;

    public get parent(): BaseCrudModal<any, any> {
        return this.$parent as BaseCrudModal<any, any>;
    }

    public cancel(): void {
        this.parent.cancel();
    }

    public save(): void {
        this.parent.save();
    }

    public deleteItem(): void {
        this.parent.deleteItem();
    }
}
