export default class DateHelper {
    public static ISODate(date: Date): string {
        return date.toISOString().substring(0, 10);
    }

    public static ISODateTime(date: Date): string {
        return date.toISOString();
    }

    public static friendlyDate(date: Date): string {
        return date.toLocaleDateString('nl', {
            weekday: 'long',
            day: '2-digit',
            month: 'long',
        });
    }

    public static friendlyTime(date: Date): string {
        return date.toLocaleTimeString('nl', {
            hour: '2-digit',
            minute: '2-digit',
        });
    }

    public static friendlyWeek(date: Date): string {
        const startOfWeek = new Date(date.setDate(date.getDate() - date.getDay() + 1));
        const formatted = startOfWeek.toLocaleDateString('nl', {
            day: '2-digit',
            month: 'long',
        });

        return `Week van ${formatted}`;
    }

    public static weekNr(date: Date): number {
        const first = new Date(date.getFullYear(), 0, 1);
        const days = Math.floor((date.getTime() - first.getTime()) / (24 * 60 * 60 * 1000));
        return Math.ceil((date.getDay() + 1 + days) / 7);
    }

    public static addDays(date: Date, days: number): Date {
        const ms = 1000 * 3600 * 24 * days;
        return new Date(date.getTime() + ms);
    }

    public static addHours(date: Date, hours: number): Date {
        const ms = 1000 * 3600 * hours;
        return new Date(date.getTime() + ms);
    }

    public static inRange(from: string, to: string, from2: string, to2: string): boolean {
        const fromDate = new Date(from).getTime();
        const toDate = new Date(to).getTime();
        const fromDate2 = new Date(from2).getTime();
        const toDate2 = new Date(to2).getTime();

        return toDate > fromDate && toDate2 > fromDate2 && fromDate2 >= fromDate && fromDate2 < toDate && toDate2 <= toDate;
    }

    public static hours(hours: number): number {
        return 3600 * 1000 * hours;
    }
}
