class PlanningState {
    public dateDayPlanning: Date = null;
    public dateWeekPlanning: Date = null;
}
const baseState = new PlanningState();

export default {
    namespaced: true,
    state: (): PlanningState => {
        return baseState;
    },
    getters: {
        dateDayPlanning(state: PlanningState): Date {
            return state.dateDayPlanning;
        },
        dateWeekPlanning(state: PlanningState): Date {
            return state.dateWeekPlanning;
        },
    },
    mutations: {
        SET_DATE_DAY_PLANNING(state: PlanningState, date: Date): void {
            state.dateDayPlanning = date;
        },
        SET_DATE_WEEK_PLANNING(state: PlanningState, date: Date): void {
            state.dateWeekPlanning = date;
        },
    },
};
