export default [
    {
        path: '/planboard',
        component: (): any => import(/* webpackChunkName: 'menu' */ '@/views/Menu.vue'),
        redirect: { name: 'planboard-week' },
        children: [
            {
                path: 'week/:view?',
                name: 'planboard-week',
                component: (): any => import(/* webpackChunkName: 'planboard' */ './views/PlanboardWeekView.vue'),
                meta: { name: 'Planboard' },
            },
            {
                path: 'trailer-day',
                name: 'planboard-day-trailer',
                component: (): any => import(/* webpackChunkName: 'planboard' */ './views/PlanboardDayView.vue'),
                meta: { name: 'Planboard trailers' },
            },
            {
                path: 'job-day',
                name: 'planboard-day-job',
                component: (): any => import(/* webpackChunkName: 'planboard' */ './views/PlanboardDayViewJob.vue'),
                meta: { name: 'Planboard klussen' },
            },
        ],
    },
];
