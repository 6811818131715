export default [
    {
        path: '/klussen',
        component: (): any => import(/* webpackChunkName: 'menu' */ '@/views/Menu.vue'),
        children: [
            {
                path: ':jobId',
                name: 'job',
                component: (): any => import(/* webpackChunkName: 'job' */ './views/JobView.vue'),
                meta: { name: 'Klus' },
            },
        ],
    },
];
