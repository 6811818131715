import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import SharedUI from '@prd/shared-ui';
import InstallOptions from '@prd/shared-ui/src/models/InstallOptions';
import { LoginService } from '@prd/shared-core/dist/services';
import Vuelidate from 'vuelidate';
import PortalVue from 'portal-vue';

import '@/components/Generic';
import '@/filters';
import './registerServiceWorker';
import '@fullcalendar/core';
import DragState from './models/DragState';

const options = new InstallOptions();
Vue.use(SharedUI, options);
Vue.use(Vuelidate);
Vue.use(PortalVue);
Vue.config.productionTip = false;

export const loginService = new LoginService({
    endpoint: Vue.$env().loginEndpoint,
    localStorageKey: 'lambalgen-auth',
});

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');

export const dragState = new DragState();
