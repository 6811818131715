import Vue from 'vue';
import LbgDateHelper from './helpers/DateHelper';
import JobCargo from './models/Job/JobCargo';
import RelationContact from './models/Relation/RelationContact';
import DateHelper from '@prd/shared-core/dist/helpers/DateHelper';
import { TimeFormat } from '@prd/shared-core/dist/helpers/TimeFormat';
import { DateFormat } from '@prd/shared-core/dist/helpers/DateFormat';

const renderContactName = (contact: RelationContact): string => {
    return [contact.firstName, contact.lastName].join(' ');
};

Vue.filter('contactName', renderContactName);
Vue.filter('formatDate', LbgDateHelper.friendlyDate);
Vue.filter('formatTime', (date: Date) => new DateHelper(date).timeString(TimeFormat.ShortTime));
Vue.filter('shortDate', (date: Date) => new DateHelper(date).dateString(DateFormat.ShortDate));
Vue.filter('shortDateTime', (date: Date) => new DateHelper(date).dateString(DateFormat.ShortDateTime));
Vue.filter('weekNr', LbgDateHelper.weekNr);

Vue.filter('formatCargo', (cargo: JobCargo) => {
    return `${cargo.length} x ${cargo.width} x ${cargo.overhang} x ${cargo.height}`;
});

export const contactName = renderContactName;
