import Vue from 'vue';

import ActionCardComponent from './ActionCard.vue';
import AddressComponent from './Address.vue';
import ButtonwithPopover from './ButtonWithPopover.vue';
import CalendarWrapper from './CalendarWrapper.vue';
import CrudModal from './CrudModal.vue';
import DateTimePicker from './DateTimePicker.vue';
import OverflowContainer from './OverflowContainer.vue';
import SizeInput from './SizeInput.vue';
import WeightInput from './WeightInput.vue';
import ExpansionPanel from './ExpansionPanel.vue';

Vue.component('prd-action-card', ActionCardComponent);
Vue.component('prd-address', AddressComponent);
Vue.component('prd-button-popover', ButtonwithPopover);
Vue.component('prd-calendar-wrapper', CalendarWrapper);
Vue.component('prd-expansion-panel', ExpansionPanel);
Vue.component('prd-crud-modal', CrudModal);
Vue.component('prd-size-input', SizeInput);
Vue.component('prd-weight-input', WeightInput);

Vue.component('date-time-picker', DateTimePicker);
Vue.component('overflow-container', OverflowContainer);
