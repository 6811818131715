export default [
    {
        path: '/beheer',
        component: (): any => import(/* webpackChunkName: 'menu' */ '@/views/Menu.vue'),
        children: [
            {
                path: 'accounts',
                name: 'accounts',
                component: (): any =>
                    import(/* webpackChunkName: 'account' */ '@/modules/account/views/AccountOverview.vue'),
                meta: { name: 'Accounts' },
            },
            {
                path: 'uitnodigingen',
                name: 'invites',
                component: (): any => import(/* webpackChunkName: 'account' */ '@/modules/account/views/InviteOverview.vue'),
                meta: { name: 'Uitnodigingen' },
            },
        ],
    },
    {
        path: '/uitnodiging/:key',
        name: 'accept-invite',
        component: (): any => import(/* webpackChunkName: 'account' */ '@/modules/account/views/AcceptInvite.vue'),
        meta: { name: 'Uitnodigingen' },
    },
];
