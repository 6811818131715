import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ButtonwithPopover extends Vue {
    @Prop({ default: 'hover focus' }) public triggers: string;
    @Prop({ default: 'bottomright' }) public placement: string;
    public id: string = '';

    public mounted(): void {
        this.id = `popover-${this.getUid()}`;
    }

    private getUid(): string {
        const self = this as any;
        return self._uid;
    }
}
